.reports-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.body-procedimento {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.relatorios-doc-icons {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.card-icon-relatorio-doc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 400px;
    max-width: 70vw;
}

.icon-relatorio-doc {
    font-size: 60px;
}

.label-icon-relatorio-doc {
    font-size: 20px;
    font-weight: bolder;
}

.div-body-user-per {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.div-relatorio-user-per {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.body-perm-sist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: 10px;
}

.card-user-reports {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 350px;
    max-width: 70%;
    height: 250px;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    border: solid;
    border-width: 0.5px;
    border-color: rgba(0, 0, 0, 0.2);
}

.card-title-reports {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 60px;
}

.card-icon-reports {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 10px;
}

.card-text-reports {
    display: flex;
    flex: 9;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    word-break: break-all;
    font-size: small;
    padding-left: 15px;
}

.body-area-user-sist {
    width: 100%;
    margin-top: 20px;
}

.painel-nome {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.nome-card, .nome-card-area-aprov {
    margin: 5px;
    padding: 6px;
    border: solid;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
}

.nome-card-area-aprov {
    display: flex;
    flex-direction: column;
}

.div-separar {
    height: 1px;
    background-color: rgba(0,0,0,0.2);
}

.buts-header {
    display: flex;
    flex-direction: row;
}

.but-resumo {
    margin-left: 10px;
}

.body-log {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.buts-select {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.buts-select .form-select:nth-child(2) {
    margin-left: 10px;
}

.buts-select .form-select:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
}

.form-select {
    display: flex;
    flex-direction: column;
}

.select-but {
    width: 150px;
    margin: 10px;
}

.paniel-logs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.erro-sistema {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    word-break: break-all;
}

.erro-sistema span:nth-child(2) {
    margin-left: 20px;
}

.dados-log, .campo-dados {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.valor-campo {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
}

.antigo-valor {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    flex: 1;
}

.novo-valor {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    flex: 1;
}

@media screen and (max-width: 800px){

    .card-icon-relatorio-doc {
        min-width: 200px;
        width: 60vw;
        max-width: 350px;
    }

    .label-icon-relatorio-doc {
        font-size: 15px;
    }

    .body-perm-sist {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        margin-top: 10px;
        width: 100%;
    }
    
    .card-user-reports {
        min-width: 250px;
        width: 350px;
        max-width: 100%;
    }

    .buts-header {
        display: flex;
        flex-direction: column;
    }
    
    .but-resumo {
        margin-left: 0px;
        margin-top: 10px;
    }

    .buts-select {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .buts-select .form-select:nth-child(2) {
        margin-left: 0px;
    }
    
    .buts-select .form-select:nth-child(3) {
        margin-top: 10px;
    }

    .valor-campo {
        flex-direction: column;
    }
}