.solicitCat-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.form-novo-alter {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    margin-left: 10px;
}

.form-add-services {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-add-services-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}

.form-add-services-div-dois {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    width: 100%;
}

.form-add-services-campos-nr {
    display: flex;
    flex-direction: row;
    width: 20%;
}

.form-add-services-campos-Solicitud {
    display: flex;
    flex-direction: row;
    width: 90%;

    & > label {
        min-width: 60px;
    }

}

.form-add-services-campos-Cantidad {
    display: flex;
    flex-direction: row;
    width: 40%;
}

.form-add-services-campos-Observaciones {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
    width: 70%;
}

.form-servico-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.aprob-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.linha-detalhe-aprov {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.div-campo-detalhe-aprov {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 5px;
}

.div-campo-detalhe-aprov-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.linha-detalhe-aprov-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.form-add-services-but {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
}

@media screen and (max-width: 800px) {
    .form-add-services {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }

    .form-add-services-campos-nr {
        display: flex;
        flex-direction: column;
        width: 20%;
    }

    .form-add-services-campos-Solicitud {
        display: flex;
        flex-direction: column;
        width: 90%;

        & > label {
            min-width: 60px;
        }
    }

    .form-add-services-campos-Cantidad {
        display: flex;
        flex-direction: column;
        width: 40%;
    }

    .form-add-services-campos-Observaciones {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 10px;
        width: 70%;
    }

    .linha-detalhe-aprov {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .div-campo-detalhe-aprov {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .div-campo-detalhe-aprov-area {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
    }
}
