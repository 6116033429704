.solicitCat-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.form-novo-alter {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    margin-left: 10px;
}

.form-add-services {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 950px;
}

.form-add-services-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}

.form-add-services-div-dois {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    width: 100%;
}

.form-add-services-campos-nr {
    display: flex;
    flex-direction: row;
    width: 20%;
}

/*.form-add-services-campos-Solicitud {
    display: flex;
    flex-direction: row;
    width: 90%;
}*/

.form-add-services-campos-Cantidad {
    display: flex;
    flex-direction: row;
    width: 30%;
}

.form-add-services-campos-Observaciones {
    display: flex;
    flex-direction: row;
    width: 70%;
}

.form-servico-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media screen and (max-width: 950px) {
    .form-add-services {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: auto;
    }

    .form-add-services-campos-nr {
        display: flex;
        flex-direction: column;
        width: 20%;
    }

    /*.form-add-services-campos-Solicitud {
        display: flex;
        flex-direction: column;
        width: 90%;
    }*/

    .form-add-services-campos-Cantidad {
        display: flex;
        flex-direction: column;
        width: 30%;
    }

    .form-add-services-campos-Observaciones {
        display: flex;
        flex-direction: column;
        width: 70%;
    }

    .form-add-services-div-dois {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }
}
