.aprob-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.filtro_tabela {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    background-color: rgba(116, 113, 113, 0.329);
}

.campos-filtro_tabela {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
}

.but_filtro_tabela {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.card-body {
    padding: 10px;
    background-color: rgba(185, 180, 180, 0.205);
    border: solid;
    border-width: 0.1px;
    border-color: rgba(224, 161, 89, 0.432);
    border-radius: 5px;
    width: 90vw;
}

.but-excel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-width: 340px;
    min-height: 0px;
    margin-top: 0px;
}

@media screen and (max-width: 800px){

    .card-body {
        padding: 10px;
        background-color: rgba(185, 180, 180, 0.205);
        border: solid;
        border-width: 0.1px;
        border-color: rgba(224, 161, 89, 0.432);
        border-radius: 5px;
        width: 85vw;
        padding-top: 28px;
    }

    .but_filtro_tabela {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 10px;
    }

    .but-excel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        min-width: 100px;
        min-height: 80px;
        margin-top: 10px;
    }
}