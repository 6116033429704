.catering-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.catering-cover {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(126, 123, 123, 0.3);
    padding: 5px;
}

.catering-title {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2px;
}

.catering-item-linha {
    display: flex;
    flex-direction: row;
    flex: 1;
    word-break: break-word;
    justify-content: space-between;
    align-items: center;
}

.catering-item-cantidad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.catering-item-text {
    display: flex;
    flex-direction: column;
}

.catering-info-modal {
    display: flex;
    flex-direction: column;
}

.body-modal {
    padding: 20px;
}

.menu-catering-data {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    flex-wrap: wrap;
}

.menu-catering-data div:nth-child(2) {
    margin-left: 10px;
}

.modelPDF {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.radio-group {
    margin-left: 20px;
}

.catering {
    &-card {
        display: flex;
        flex-direction: column;
        //width: fit-content;
        width: 100%;
        padding: 10px;
        border-width: 2px;
        border-style: dashed;
        border-radius: 5px;
        border-color: rgba(1, 1, 1, 0.2);

        &-info {
            display: flex;

            &-detales {
                display: flex;
                flex-direction: column;
                padding: 10px;
            }
        }

        &-botao {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            margin-top: 10px;
        }

        &-token {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 800px) {
    .radio-group {
        margin-left: 0px;
        margin-top: 10px;
    }

    .menu-catering-data {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px;
        flex-wrap: wrap;
    }
}
