.filto-user-docum{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-novo-alter {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    margin-left: 10px;
}

@media screen and (max-width: 800px){

    .filto-user-docum{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .list-item {
        display: flex;
        flex-direction: column;
    }

}