.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.sider-lateral {
  min-height: 100vh;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: rgb(255, 255, 255);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.header-menu-geral {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-id-menu {
  margin-right: 20px;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.modal-info-vesion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: dashed;
  border-width: 1px;
  margin: 20px;
  padding: 10px;
}

.modal-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 800px){

  .title-site {
    font-size: 20px !important;
  }

}
