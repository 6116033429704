.image-body{
    padding: 5px;
    color: rgb(1, 1, 1);
    background-color: #364d79;
}

.image-body-child {
    display: flex;
    align-items: center;
    justify-content: center;
}