//css do ant-design
@import "~antd/dist/reset.css";

.menu-adm-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
    .menu-adm-header {
        flex-direction: column;
        width: 100%;
    }
}

@import "menu";
@import "catering";
@import "config";
@import "home";
@import "mySolicHist";
@import "reportes";
@import "solicAprov";
@import "solicCat";
